/**
 * Part of the Banzai CMS
 *
 * NOTICE OF LICENSE
 *
 * Licensed under the Cartalyst PSL License.
 *
 * This source file is subject to the Cartalyst PSL License that is
 * bundled with this package in the license.txt file.
 *
 * @package    Platform
 * @version    2.0.0
 * @author     Cartalyst LLC
 * @license    Cartalyst PSL
 * @copyright  (c) 2011-2014, Cartalyst LLC
 * @link       http://cartalyst.com
 */

var Woot;

;(function(window, document, $, undefined)
{

    'use strict';

    Woot = Woot || {
        App: {},
        Urls: {},
        Cache: {},
    };

    // Woot Base URL
    // Woot.Urls.base = $('meta[name="base_url"]').attr('content');

    // CSRF on AJAX requests
    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
    });

    // Cache common selectors
    Woot.Cache.$win   = $(window);
    Woot.Cache.$body  = $(document.body);
    Woot.Cache.$alert = $('[data-alert]');

    // Initialize functions
    Woot.App.init = function()
    {
        Woot.App
            .listeners()
            .validation()
        ;
    };

    // Add Listeners
    Woot.App.listeners = function()
    {
        Woot.Cache.$alert
            .on('click', '.alert-close', Woot.App.alerts)
        ;

        // Woot.Cache.$body
        //     .on('keyup', '[data-slugify]', Woot.App.slugify)
        //     .on('click', '.toggle-sidebar', Woot.App.sidebarToggle)
        //     .on('click', '[data-action-delete]', Woot.App.deletion)
        //     .on('click', '[data-modal], [data-toggle="modal"]', Woot.App.modals)
        // ;

        return this;
    };

    // Initialize the form validation
    Woot.App.validation = function()
    {
        window.ParsleyConfig = {
            errorClass: 'has-error',
            successClass: 'has-success',
            classHandler: function(Field)
            {
                return Field.$element.parents('.form-group');
            },
            errorsContainer: function(Field)
            {
                return Field.$element.parents('.form-group');
            },
            errorsWrapper: '<span class=\"help-block text-danger\"></span>',
            errorTemplate: '<span></span>',
        };

        if ($('[data-parsley-validate]').length > 0)
        {
            $(document).ready(function()
            {
                $.listen('parsley:field:success', function(Field)
                {
                    // console.log('parsley:field:success', Field);
                    Field.$element.closest('.form-group').find('.help-block').show();
                });

                $.listen('parsley:field:error', function(Field)
                {
                    // console.log('parsley:field:error', Field);
                    // Field.$element.closest('.form-group').find('.help-block').hide();
                });
            });
        }

        return this;
    };

    // Handle Alerts
    Woot.App.alerts = function(event)
    {
        $(event.delegateTarget).slideToggle(function()
        {
            $(this)
                .removeClass('alert-show')
                .addClass('alert-hide')
                .remove()
            ;
        });
    };

    // Handle Bootstrap Modals
    Woot.App.modals = function(event, message, target, callback)
    {
        event.preventDefault();

        // Get the modal target
        var target = target ? target : $(this).data('target');

        // Is this modal target a notice?
        if (target === 'modal-notice')
        {
            if ( ! callback)
            {
                callback = function()
                {
                    $('#modal-notice').modal('hide');
                };
            }

            $('#modal-notice .lead').text(message);

            $('#modal-notice').modal({
                show: true,
                remote: false,
            });

            return false;
        }

        // Is this modal target a confirmation?
        if (target === 'modal-confirm')
        {
            if ( ! callback)
            {
                callback = function()
                {
                    $('#modal-confirm').modal('hide');
                };
            }

            $('#modal-confirm .confirm')
                .attr('href', $(this).attr('href'))
                .on('click', callback)
            ;

            $('#modal-confirm').modal({
                show: true,
                remote: false,
            });

            return false;
        }
    };

    // Job done, lets run
    Woot.App.init();

})(window, document, jQuery);
